import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

export const DeepLink = ({ children }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tgWebAppStartParam = searchParams.get('tgWebAppStartParam')

    useEffect(() => {
        if (tgWebAppStartParam) {

            if (tgWebAppStartParam === 'create') {
                navigate('/character/create')
                return
            }

            if (tgWebAppStartParam === 'profile') {
                navigate('/profile')
                return
            }

            const characterId = parseInt(tgWebAppStartParam)

            console.log(characterId)

            if (characterId && !isNaN(characterId)) {
                navigate(`/character/${characterId}`)
                return
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children;
}
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Home } from './pages/Home';
import { Show } from './pages/Show';
import { Edit } from './pages/Edit';
import { Create } from './pages/Create';
import { Profile } from './pages/Profile';
import { Chats } from './pages/Chats';
import { ConnectTgBot } from './pages/ConnectTgBot';

import { DeepLink } from './components/DeepLink';
import { UserProvider } from './components/UserProvider';

import fonts from './styles/fonts.module.css';
import styles from './App.module.css';

const App = () => {

  useEffect(() => {
    const tg = window.Telegram.WebApp

    tg.setBackgroundColor('#0a282c')
    tg.setBottomBarColor('#0a282c')
    tg.setHeaderColor('#0a282c')
  }, []);

  return (
    <div className={[fonts.root, styles.app].join(' ')}>
      <BrowserRouter>
        <UserProvider>
          <DeepLink>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/character/create" element={<Create />} />
              <Route path="/character/:id" element={<Show />} />
              <Route path="/character/edit/:id" element={<Edit />} />
              <Route path="/character/tg/connect/:id" element={<ConnectTgBot />} />
              <Route path="/chats" element={<Chats />} />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </DeepLink>
        </UserProvider>
      </BrowserRouter>
    </div>
  )
};

export default App;
import { Textarea } from './Textarea'

export function Appearance({ value, onChange, error, generate }) {
    return (
        <Textarea
            name="appearance"
            text="The description of the character's appearance affects the generation of his images. To achieve the best results, add LORA model in Lora tab."
            placeholder="Appearance"
            onChange={onChange}
            maxLength={500}
            value={value}
            error={error}
            generate={generate}
        />
    )
}
import { IoEyeOutline, IoEyeOffOutline, IoHeartOutline, IoBookmarkOutline, IoChatbubbleOutline } from "react-icons/io5";

import styles from './CharacterPreview.module.css';

export function CharacterPreview({ user, character }) {
    return (
        <div className={styles.info}>
            {user.is_adult && <div className={styles.isAdult}>{character.is_adult ? <><IoHeartOutline /> <span>18+</span></> : ''}</div>}
            <div className={styles.rating + ' ' + styles[character.rating]}>{character.rating.replace('_', '-')}</div>
            <div className={styles.visability}>{character.is_public ? <IoEyeOutline /> : <IoEyeOffOutline />}</div>
            <div className={styles.avatar} style={character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#000' }}> {character.avatar_url ? '' : 'no avatar'} </div>
            <span className={styles.name}>{character.name}</span>
            <span className={styles.description}>{character.bio.slice(0, 100)}...</span>
            <div className={styles.tags}>
                {
                    character.tags.map((tag) => (
                        <span key={tag.id} className={styles.tag}>{tag.name}</span>
                    ))
                }
            </div>
            <div className={styles.stats}>
                <div>
                    <IoHeartOutline />
                    <span>{character?._count.likes}</span>
                </div>
                <div>
                    <IoBookmarkOutline />
                    <span>{character?._count.favorites}</span>
                </div>
                <div>
                    <IoChatbubbleOutline />
                    <span>{character?._count.chats}</span>
                </div>
            </div>
        </div>
    )
}

import { IoPencil, IoHeartOutline } from "react-icons/io5";

import { Form } from '../../../components/character/Form';
import { CropAvatar } from '../../../components/CropAvatar';

import styles from '../Edit.module.css';

export function Profile ({
  userData,
  character,
  onChange,
  generate,
  avatarFileRef,
  avatarUploadLoading,
  newAvatar,
  onAvatarChange,
  onAvatarUpload,
  onAvatarSaveClick,
  onAvatarCancelClick,
  onAvatarEditClick,
  onImportClick,
  importOpen,
  formErrors,
  setCharacter,
  onChangePublish,
  onChangeAdult,
  openModerationGuide,
  moderationWarnings,
  onImportFromUrlClick,
}) {
    return (
    <>
      <div className={styles.importContainer}>
        <button className={styles.button + ' ' + styles.import} onClick={onImportClick}>
          <span>Generate ⭐️</span>
        </button>
        <div className={styles.importOptions + ' ' + (importOpen ? styles.importOptionsOpen : '')}>
          <button className={styles.button + ' ' + styles.importFromUrl} onClick={onImportFromUrlClick}>
            <span>URL</span>
          </button>
        </div>
      </div>

      <div className={styles.field + ' ' + styles.ratingContainer}>
        {
          character?.rating && (
            <>
              <span className={styles.rating + ' ' + styles[character?.rating]}>{character?.rating.replace('_', '-')}</span>
              { userData && userData.is_adult && character.is_adult && <span className={styles.isAdult}>{<><IoHeartOutline /> <span>18+</span></>}</span> }
            </>
          )
        }
      </div>

      <span className={styles.name}>{character?.name}</span>

      {newAvatar && (
        <div className={styles.field}>
          <div className={styles.crop}>
            <CropAvatar
              src={newAvatar}
              loading={avatarUploadLoading}
              onAvatarSaveClick={onAvatarSaveClick}
              onAvatarCancelClick={onAvatarCancelClick}
              onAvatarUpload={onAvatarUpload}
            />
          </div>
        </div>
      )}

      <div className={styles.avatarContainer} style={newAvatar ? { display: 'none' } : {}}>
        <div className={styles.avatar} style={character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#18373a' }} onClick={onAvatarEditClick}> {character.avatar_url ? '' : 'no avatar'}
          <div className={styles.avatarEdit}>
            <IoPencil size={20} />
          </div>
          <input
            ref={avatarFileRef}
            type="file"
            accept="image/jpeg, image/png, image/webp"
            onChange={onAvatarChange}
            style={{ display: 'none' }} />
        </div>
      </div>

      {
        Object.keys(moderationWarnings).length > 0 && (
          <div className={styles.field + ' ' + styles.moderation_score}>
            { character.is_not_safe && <span>⚠️ Your character was marked as not safe for children</span> }
            <span> ⚠️ Character has the following moderation warnings: </span>
            <div>
              {
                Object.entries(moderationWarnings).map(([key, value]) => (
                  value && <div key={key}>{key}: <span className={ value <= 0.2 ? styles.alert : value <= 0.5 ? styles.warning : styles.danger } >{value.toFixed(2)}</span></div>
              ))
              }
            </div>
            <a href="#genrate" onClick={openModerationGuide}>What does it mean?</a>
          </div>
        )
      }

      <div className={styles.field}>
        <div className={styles.visability}>
            <span>Private</span>
            <label className={styles.switch}>
              <input type="checkbox" checked={character.is_public} onChange={onChangePublish} value={character.is_public} />
              <span className={styles.slider}></span>
            </label>
            <span>Public</span>
        </div>
      </div>
      <span className={styles.text}>Public character available to interact with other users</span>

    {
      userData && userData.is_adult && (
        <>
          <div className={styles.field}>
            <div className={styles.visability}>
              <span>For adult audience</span>
              <label className={styles.switch}>
                <input type="checkbox" checked={character.is_adult} onChange={onChangeAdult} value={character.is_adult} />
                <span className={styles.slider}></span>
              </label>
              <span>Enable</span>
            </div>
          </div>
          <span className={styles.text}>Character will be able to generate adult content, and will be visible only to adult audience</span>
        </>
      )}

      {/* {
        character._count.telegram_bots === 0 && (
          <>
            <span className={styles.text}>You need to connect at least one public telegram bot to the character first to make it public <a href="#" onClick={() => navigate(`/character/tg/connect/${character.id}`)}>Connect bot</a></span>
          </>
        )
      } */}

      <Form character={character} formErrors={formErrors} setCharacter={setCharacter} onChange={onChange} generate={generate} />

    </>
  )
}

import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import styles from './ConnectTgBot.module.css';

import { Loader } from '../../components/Loader';
import { useApi, ApiError } from '../../hooks/useAPI';
import { UserContext } from '../../components/UserProvider';

let tg = window.Telegram.WebApp;

export function ConnectTgBot() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [character, setCharacter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [connectBotOpen, setConnectBotOpen] = useState(false);
  const [botToken, setBotToken] = useState('');
  const [botTokenError, setBotTokenError] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const apiOptions = useMemo(() => ({
    baseUrl: process.env.REACT_APP_API_URL,
    requestOptions: {
      headers: {
        'x-init-data': tg.initData,
      }
    }
  }), [])

  const { character: { useGet: getCharacter, useConnectTgBot: connectTgBot } } = useApi(apiOptions)
  const { fetch: getCharacterFetch, data: getCharacterData } = getCharacter(id)
  const { fetch: connectTgBotFetch, data: connectTgBotData, loading: connectTgBotLoading } = connectTgBot(id)

  const { user } = useContext(UserContext);

  useEffect(() => {
    tg.BackButton.show()
    tg.BackButton.onClick(() => navigate(`/character/${id}`))
    tg.expand()

    if (!tg.isFullscreen) {
      if ('requestFullscreen' in tg && ['android', 'ios'].includes(tg.platform)) {
        try {
          tg.requestFullscreen()
          setFullscreen(true)
        } catch (e) {}
      }
    } else {
      setFullscreen(true)
    }

    return () => {
      tg.BackButton.offClick()
    }
  }, [id, navigate]);

  useEffect(() => {
    if (!connectTgBotData) return
    navigate(`/character/${id}`)
  }, [connectTgBotData, id, navigate])

  useEffect(() => {
    if (!user) return
    getCharacterFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // useEffect(() => {
  //   console.log(connectTgBotError)

  //   if (getCharacterError) {
  //     setErrors([...errors, getCharacterError])
  //   }

  //   if (connectTgBotError) {
  //     setErrors([...errors, connectTgBotError])
  //   }

  //   const timeout = setTimeout(() => {
  //     setErrors([])
  //   }, 3000)

  //   return () => clearTimeout(timeout)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getCharacterError, connectTgBotError])

  useEffect(() => {
    if (!getCharacterData) return;
    setCharacter(getCharacterData)
    setLoading(false)
  }, [getCharacterData])

  const onChangeBotToken = useCallback((e) => {
    setBotToken(e.target.value)
  }, [])

  const onConnectBot = useCallback(() => {
    const isValidToken = /^[0-9]{8,10}:[a-zA-Z0-9_-]{35}$/.test(botToken);

    if (!isValidToken) {
      setBotTokenError(true)
      return
    }

    setBotTokenError(false)
    connectTgBotFetch({ body: JSON.stringify({ token: botToken, is_public: isPublic }) }).catch((e) => {
      if (e instanceof ApiError) {
        setErrors([...errors, e.response.error])
      } else {
        setErrors([...errors, 'Something went wrong'])
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botToken, isPublic, connectTgBotFetch])

  const onChangeIsPublic = useCallback((e) => {
    setIsPublic(e.target.checked)
  }, [])

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.connectTgBot + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '')}>
      {errors.length > 0 && (
        <div className={styles.error}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      <div className={styles.container}>
        <div className={styles.avatarContainer}>
          <div className={styles.avatar} style={character.avatar_url ? { backgroundImage: `url(${character.avatar_url})` } : { 'backgroundColor': '#18373a' }}> {character.avatar_url ? '' : 'no avatar'} </div>
          <div className={styles.nameContainer}>
            <span className={styles.name}>{character?.name}</span>
          </div>
        </div>

        <div className={styles.field}>
          <p><b>How to Connect Your Telegram Bot to a Character</b></p>
          <p>Follow these simple steps to connect your Telegram bot to a character and start interacting on their behalf.</p>
          <p>If you have already bot token, press the button below.</p>
          {
            connectBotOpen ? (<>
              <div className={styles.connectBotInput}>
                <input disabled={connectTgBotLoading} className={botTokenError ? styles.inputError : ''} type="text" placeholder="Enter bot token here..." value={botToken} onChange={onChangeBotToken} />
              </div>
              <div className={styles.connectBotIsPublic}>
                <input id='connectBotIsPublic' type="checkbox" checked={isPublic} onChange={onChangeIsPublic} />
                <label htmlFor='connectBotIsPublic'>Make this bot public (other users will be able to have a chat with it)</label>
              </div>
              <button disabled={connectTgBotLoading} className={styles.button} onClick={onConnectBot}>Connect</button>
              </>
            ) : (
              <button onClick={() => setConnectBotOpen(true)} className={styles.button}>Connect to a new bot</button>
            )
          }
        </div>

        <div className={styles.field}>
          <p><b>Create a Telegram Bot</b></p>
          <p>If you don’t have a bot yet, follow these steps:</p>
          <ol>
            <li>
              Open Telegram bot <span className={styles.code}>@BotFather</span> (the official bot for managing Telegram bots).
              <button onClick={() => tg.openTelegramLink('https://t.me/BotFather')} className={styles.button}>Open @BotFather</button>
            </li>
            <li>Click Start (or type <span className={styles.code}>/start</span> command) to begin.</li>
            <li>Type the command <span className={styles.code}>/newbot</span> to create a new bot.</li>
            <li>Follow BotFather’s instructions:</li>
            <ul>
              <li>Provide a name for your bot (e.g., "MyPerfectAssistant").</li>
              <li>Provide a unique <span className={styles.code}>@username</span> for your bot (e.g., @MyPerfectAssistantBot).</li>
            </ul>
            <li>Once the bot is created, BotFather will send you a bot token (e.g., <span className={styles.code}>123456:ABC-DEF1234ghIkl-zyx57W2v1u123ew11</span>).</li>
            <ul>
              <li>Copy this token as you’ll need it to connect the bot.</li>
              <li>Go back to the app and presss to "Connect to a new bot" button and paste the token.</li>
            </ul>
          </ol>
        </div>
      </div>
    </div>
  )
}

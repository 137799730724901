import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { IoHomeOutline, IoChatbubbleOutline, IoAddCircleOutline, IoPersonOutline, IoEyeOutline, IoEyeOffOutline, IoTrash } from 'react-icons/io5';
import { FaTelegramPlane } from 'react-icons/fa';

import { useApi } from '../../hooks/useAPI';
import { Loader } from '../../components/Loader';
import { UserContext } from '../../components/UserProvider';

import styles from './Profile.module.css';

let tg = window.Telegram.WebApp;

const BROWSE_LEVELS = ['PG', 'PG_13', 'R', 'X', 'XXX']

export function Profile() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultTab = searchParams.get('tab');

    const [tab, setTab] = useState(defaultTab || 'profile');
    const [fullscreen, setFullscreen] = useState(false);
    const [amount, setAmount] = useState(5000);
    const [invoiceError, setInvoiceError] = useState(null);
    const [nicknameIsChanged, setNicknameIsChanged] = useState(false);
    const [nicknameError, setNicknameError] = useState(null);
    const [openedBot, setOpenedBot] = useState(null);
    const [botDeleteBotnames, setBotDeleteBotnames] = useState({});
    const [botDeleteTimeouts, setBotDeleteTimeouts] = useState({});
    const [success, setSuccess] = useState(null);
    const [browsingLevel, setBrowsingLevel] = useState(null);
    const [ratings, setRatings] = useState({
        'PG': ['PG', 'PG_13'],
        'PG_13': ['PG', 'PG_13'],
    });

    const [username, setUsername] = useState(null);
    const [language, setLanguage] = useState(null);
    const [isAdult, setIsAdult] = useState(null);

    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const { user: { useInvoice, useUpdateNickname, useBots, useSetLanguage, useEnableAdult, useBrowsingLevel }, character: { useDisconnectTgBot } } = useApi(apiOptions)
    const { fetch: fetchInvoice } = useInvoice();
    const { fetch: fetchUpdateNickname, loading: updateNicknameLoading } = useUpdateNickname();
    const { fetch: botsFetch, data: botsData } = useBots();
    const { fetch: disconnectTgBotFetch, loading: disconnectTgBotLoading } = useDisconnectTgBot()
    const { fetch: fetchBrowsingLevel } = useBrowsingLevel()
    const { fetch: fetchSetLanguage, loading: setLanguageLoading } = useSetLanguage()
    const { fetch: fetchEnableAdult } = useEnableAdult()

    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        if (!isAdult) {
            setRatings({
                'PG': ['PG', 'PG_13'],
                'PG_13': ['PG', 'PG_13'],
            })
        } else {
            setRatings({
                'PG': ['PG', 'PG_13', 'R', 'X', 'XXX'],
                'PG_13': ['PG', 'PG_13', 'R', 'X', 'XXX'],
                'R': ['PG', 'PG_13', 'R', 'X', 'XXX'],
                'X': ['PG', 'PG_13', 'R', 'X', 'XXX'],
                'XXX': ['PG', 'PG_13', 'R', 'X', 'XXX'],
            })
        }
    }, [isAdult])

    useEffect(() => {
        tg.expand()
        tg.BackButton.show()
        tg.BackButton.onClick(() => navigate(`/`))
        tg.disableVerticalSwipes()

        if (!tg.isFullscreen) {
            if ('requestFullscreen' in tg && ['android', 'ios'].includes(tg.platform)) {
                try {
                    tg.requestFullscreen()
                    setFullscreen(true)
                } catch (e) { }
            }
        } else {
            setFullscreen(true)
        }
    }, [navigate])

    useEffect(() => {
        if (!user) return;
        botsFetch()
        setUsername(user.nickname)
        setLanguage(user.language_code)
        setIsAdult(user.is_adult)
        setBrowsingLevel(BROWSE_LEVELS.indexOf(user.filter_rating))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const onChangeAmount = useCallback((value) => {
        setInvoiceError(null)
        setAmount(value)
    }, [])

    const onAddCharacterClick = useCallback(() => navigate(`/character/create`), [navigate])
    const onChatsClick = useCallback(() => navigate(`/chats`), [navigate])
    const onHomeClick = useCallback(() => navigate(`/`), [navigate])

    const onSendInvoice = useCallback((amount) => {
        if (amount < 100) {
            setAmount(100);
            setInvoiceError(' Minimum purchase amount is 100 Credits ⭐.');
            return;
        }

        if (amount % 100 !== 0) {
            setAmount(Math.ceil(amount / 100) * 100);
            setInvoiceError('Amount must be a multiple of 100.');
            return;
        }

        if (amount > 10000000) {
            setAmount(10000000);
            setInvoiceError('Maximum purchase amount is 10 000 000 Credits ⭐.');
            return;
        }

        fetchInvoice({ body: JSON.stringify({ amount }) }).then((data) => {
            console.log(data);
        }).catch((error) => {
            console.error(error);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeNickname = useCallback((e) => {
        setNicknameIsChanged(e.target.value !== username);
        setUsername(e.target.value.toLowerCase().replace(/[^a-z0-9_]/g, '').trim())
        setUser((prevUser) => ({ ...prevUser, nickname: username }))
    }, [username, setUser]);

    const onSaveNickname = useCallback(() => {
        setNicknameError(null)
        console.log(username)
        fetchUpdateNickname({ body: JSON.stringify({ nickname: username }) }).then((data) => {
            setSuccess('Nickname changed successfully')
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }).catch((error) => {
            setNicknameError(error.message)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username])

    const onChangeTab = useCallback((tab) => () => {
        setTab(tab)
        setSearchParams({ tab })
    }, [setTab, setSearchParams])

    const onBotClick = useCallback((botname) => () => {
        tg.openTelegramLink(`https://t.me/${botname}`)
    }, [])

    const onOpenBotClick = useCallback((botname) => () => {
        if (botDeleteBotnames[botname]) return;
        if (openedBot === botname) {
            setOpenedBot(null)
        } else {
            setOpenedBot(botname)
        }
    }, [openedBot, botDeleteBotnames])

    const onBotDelete = useCallback((character_id, botname) => async () => {
        if (botDeleteBotnames[botname]) return;
        setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: true })

          setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: setTimeout(() => {
            disconnectTgBotFetch({ body: JSON.stringify({ id: character_id, telegram_botname: botname }) }).then(() => {
              botsFetch()
              setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false })
              setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null })
              setOpenedBot(null)
            }).catch((error) => {
              alert(`Bot ${botname} deletion failed: ${error}`)
            })
          }, 3100) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botDeleteBotnames])

    const onCancelBotDelete = useCallback((botname) => () => {
        if (disconnectTgBotLoading) return;
        setBotDeleteBotnames({ ...botDeleteBotnames, [botname]: false })
        clearTimeout(botDeleteTimeouts[botname])
        setBotDeleteTimeouts({ ...botDeleteTimeouts, [botname]: null })
    }, [botDeleteBotnames, botDeleteTimeouts, disconnectTgBotLoading])

    const onChangeLanguage = useCallback((e) => {
        setLanguage(e.target.value)
        setUser((prevUser) => ({ ...prevUser, language_code: e.target.value }))
        fetchSetLanguage({ body: JSON.stringify({ language: e.target.value }) }).then((data) => {
            setSuccess('Language changed successfully')
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }).catch((error) => {
            console.error(error)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeAdult = useCallback((e) => {
        setIsAdult(e.target.checked)
        setUser((prevUser) => ({ ...prevUser, is_adult: e.target.checked, filter_rating: e.target.checked ? 'R' : 'PG' }))
        fetchEnableAdult({ body: JSON.stringify({ is_adult: e.target.checked }) }).then((data) => {
            setSuccess('Adult content is ' + (e.target.checked ? 'enabled' : 'disabled'))
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
            setIsAdult(data.is_adult)
        }).catch((error) => {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdult])

    const onChangeBrowsingLevel = useCallback((level) => () => {
        setBrowsingLevel(level)
        setUser((prevUser) => ({ ...prevUser, filter_rating: BROWSE_LEVELS[level] }))
        fetchBrowsingLevel({ body: JSON.stringify({ browsing_level: BROWSE_LEVELS[level] }) }).then((data) => {
            setSuccess('Browsing level changed successfully')
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }).catch((error) => {})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [browsingLevel])

    if (!user) {
        return <Loader />;
    }

    return (
        <div className={styles.profile + ' ' + styles[tg.platform] + ' ' + (fullscreen ? styles.fullscreen : '')}>
            <div className={styles.container}>
                <div className={styles.tabs}>
                    <div data-tab="profile" className={styles.tab + ' ' + (tab === 'profile' ? styles.active : '')} onClick={onChangeTab('profile')}>
                        <span>Profile</span>
                    </div>
                    <div data-tab="bots" className={styles.tab + ' ' + (tab === 'bots' ? styles.active : '')} onClick={onChangeTab('bots')}>
                        <span>Bots</span>
                    </div>
                </div>

                {success && (
                    <div className={styles.success}>
                        <div>{success}</div>
                    </div>
                )}

                {tab === 'profile' && (
                    <>
                        <div className={styles.field + ' ' + styles.balance}>
                            <div className={styles.fieldLabel}>
                                Your balance
                            </div>
                            <div className={styles.fieldValue}>
                                <span>{user.stars} ⭐</span>
                            </div>
                        </div>

                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>
                                Account name
                            </div>
                            <div className={styles.fieldValue + ' ' + styles.nickname + ' ' + (nicknameError ? styles.fieldError : '')}>
                                <input disabled={updateNicknameLoading} value={username || user.nickname} onChange={onChangeNickname} />
                                {nicknameIsChanged && <button disabled={updateNicknameLoading} onClick={onSaveNickname}>Save</button>}
                            </div>
                            {nicknameError && <div className={styles.errorMessage}>{nicknameError}</div>}
                        </div>

                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>Language</div>
                            <div className={styles.fieldValue}>
                                <div className={styles.language}>
                                    <select disabled={setLanguageLoading || setLanguageLoading} value={language || user.language_code} onChange={onChangeLanguage}>
                                        <option value="en">English</option>
                                        <option value="ar">Arabic</option>
                                        <option value="ru">Russian</option>
                                        <option value="fr">French</option>
                                        <option value="hi">Hindi</option>
                                        <option value="es">Spanish</option>
                                        <option value="fa">Farsi</option>
                                        <option value="de">German</option>
                                        <option value="kk">Kazakh</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={styles.field}>
                            <div className={styles.fieldLabel}>
                                <div className={styles.fieldLabel}>Enable NSFW/adult content</div>
                                <label className={styles.switch}>
                                    <input type="checkbox" checked={isAdult || false} onChange={onChangeAdult} value={isAdult} />
                                    <span className={styles.slider}></span>
                                </label>
                            </div>
                        </div>

                        {
                            <div className={styles.field}>
                                <div className={styles.fieldLabel}>
                                    Browsing Level
                                </div>
                                <div className={styles.fieldValue}>
                                    <div className={styles.browsingLevel}>
                                        { user.filter_rating in ratings && ratings[user.filter_rating].map((rating, index) => (
                                            <div onClick={onChangeBrowsingLevel(index)} className={browsingLevel >= index ? styles.active : ''}>{rating.replace('_', '-')}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={styles.field + ' ' + styles.buyStars + ' ' + (invoiceError ? styles.fieldError : '')}>
                            <div>
                                <span>Buy Credits ⭐</span>
                                <span>1 Telegram Star = 100 Credits</span>
                            </div>
                            <div className={styles.buyStarsButtons}>
                                <button onClick={() => onChangeAmount(25000)}>25.000</button>
                                <button onClick={() => onChangeAmount(30000)}>30.000</button>
                                <button onClick={() => onChangeAmount(50000)}>50.000</button>
                                <button onClick={() => onChangeAmount(100000)}>100.000</button>
                            </div>
                            <div>
                                <span>Credits</span>
                                <input value={amount || 0} onChange={(e) => onChangeAmount(e.target.value)} /> ⭐
                                <button onClick={() => onSendInvoice(amount)}>Buy</button>
                            </div>
                            {invoiceError && <div className={styles.errorMessage}>{invoiceError}</div>}
                        </div>
                    </>
                )}

                {tab === 'bots' && (
                    <div className={styles.bots}>
                        {
                            botsData?.bots.length === 0 && (
                                <div className={styles.menuTitle}><p>You haven't connected any bots yet.</p></div>
                            )
                        }
                        {
                            botsData?.bots.map((bot) => (
                                <div key={bot.botname} className={styles.bot + ' ' + (openedBot === bot.botname ? styles.botOpened : '') + ' ' + (botDeleteBotnames[bot.botname] ? styles.botDeleting : '')} onClick={onOpenBotClick(bot.botname)}>
                                    <div className={styles.botDeletingOverlay} />
                                    {!disconnectTgBotLoading && <div className={styles.botDeletingText} onClick={onCancelBotDelete(bot.botname)}>Tap to cancel</div>}
                                    <div className={styles.botContent}>
                                        <div className={styles.botHeader}>
                                            <div>
                                                <span className={styles.botname}>@{bot.botname}</span>
                                            </div>
                                            <div>
                                                <span className={styles.botAuthor}>@{bot.user.nickname}</span>
                                            </div>
                                        </div>
                                        <div className={styles.botFooter}>
                                            <div className={styles.botVisability}>
                                                {bot.is_public ? <IoEyeOutline /> : <IoEyeOffOutline />}
                                            </div>
                                            <div className={styles.botBubble}>
                                                <IoChatbubbleOutline />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.botActions}>
                                        <button onClick={onBotClick(bot.botname)} className={styles.button}>
                                            <FaTelegramPlane size={22} />
                                        </button>
                                        <button onClick={onBotDelete(bot.character.id, bot.botname)} className={styles.button}>
                                            <IoTrash size={22} />
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )}
            </div>

            <footer className={styles.footer}>
                <div className={styles.footerTabs}>
                    <button onClick={onHomeClick}><IoHomeOutline /></button>
                    <button onClick={onChatsClick}><IoChatbubbleOutline /></button>
                    <button onClick={onAddCharacterClick}><IoAddCircleOutline /></button>
                    <button ><IoPersonOutline /></button>
                </div>
            </footer>
        </div>
    );
}

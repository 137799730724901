import { useEffect, useMemo, useState, useCallback } from 'react';
import { useApi } from '../../../hooks/useAPI';
import styles from './Lora.module.css';

let tg = window.Telegram.WebApp;

export function Lora({
    character,
    setCharacter,
}) {
    const [search, setSearch] = useState(character.name)
    const [lora, setLora] = useState(character.lora)
    const apiOptions = useMemo(() => ({
        baseUrl: process.env.REACT_APP_API_URL,
        requestOptions: {
            headers: {
                'x-init-data': tg.initData,
            }
        }
    }), [])

    const { character: { useSearchLora: searchLora } } = useApi(apiOptions)
    const { fetch: searchLoraFetch, data: searchLoraItems, loading: searchLoraLoading } = searchLora(character.id)

    useEffect(() => {
        searchLoraFetch({
            query: {
                query: search,
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const selectLora = useCallback((item) => {
        setLora(item)
        setCharacter({
            ...character,
            lora: item,
        })
    }, [setCharacter, character])

    return (
        <div className={styles.loraContainer}>
            {
                character.lora && (
                    <div className={styles.currentLoraItem}>
                        <div>
                            <div><a href={character.lora.url} target="_blank" rel="noopener noreferrer">{character.lora.name}</a></div>
                            {
                                character.lora.images.map((image) => (
                                    <img src={image.url} alt={image.url} key={image.url} />
                                ))
                            }
                        </div>
                    </div>
                )
            }

            <div className={styles.search}>
                <input type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
                <button onClick={() => searchLoraFetch({
                    query: {
                        query: search,
                    }
                })}>Search</button>
            </div>

            {
                (searchLoraLoading) && (
                    <div className={styles.spinnerOverlay} onClick={(e) => e.stopPropagation()}>
                        <span>Searching...</span>
                        <div className={styles.spinner} />
                    </div>
                )
            }

            {searchLoraItems && searchLoraItems.length > 0 ? searchLoraItems.map((item) => (
                <div key={item.air} className={styles.loraItem + ' ' + (lora && lora.air === item.air ? styles.selectedLoraItem : '')} onClick={() => selectLora(item)}>
                    <div>{item.name} by <a href={'https://civitai.com/user/' + item.creator.username} target="_blank" rel="noopener noreferrer">{item.creator.username}</a></div>
                    <div>
                        <div className={styles.loraItemImages}>
                            {
                                item.images.map((image) => (
                                    <img src={image.url} alt={image.url} key={image.url} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            )) : (
                <div className={styles.loraItem}>
                    <div>No results</div>
                </div>
            )}
        </div>
    )
} 
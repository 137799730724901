import { useState, useCallback, useMemo } from 'react';

export class ApiError extends Error {
    constructor(message, response) {
        super(message);
        this.response = response;
    }
}

const useApiRequest = (baseUrl, endpoint, requestOptions) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [timeoutFetch, setFetchTimeout] = useState(null);
    const [activeRequest, setActiveRequest] = useState(null);

    // add throttling
    const fetchApi = useCallback(async (fetchOptions) => {
        setLoading(true);
        setError(null);
        setData(null);

        if (timeoutFetch) {
            clearTimeout(timeoutFetch);
        }

        const requestKey = JSON.stringify({
            url: `${baseUrl}${endpoint}`,
            options: fetchOptions
        });

        if (activeRequest && activeRequest.key === requestKey) {
            return activeRequest.promise;
        }

        const promise = new Promise((resolve, reject) => {
            setFetchTimeout(setTimeout(async () => {
                try {
                    const headers = {
                        'Content-Type': 'application/json',
                        ...requestOptions.headers,
                    };

                    const { query, ..._fetchOptions } = fetchOptions || {}

                    const options = {
                        headers,
                        ...requestOptions,
                        ..._fetchOptions,
                    };

                    setActiveRequest({ key: requestKey, promise });

                    let response = await fetch(`${baseUrl}${endpoint}` + (query ? `?${new URLSearchParams(query).toString()}&t=${new Date().getTime()}` : '?t=' + new Date().getTime()), options);

                    if (!response.ok) {
                        const error = await response.json()
                        throw new ApiError(`Error: ${error.error}`, error );
                    }

                    const data = await response.json()

                    setData(data)

                    return resolve(data);
                } catch (err) {
                    if (err instanceof ApiError) {
                        setError(err);
                    } else {
                        setError('Unknown error occurred');
                    }

                    return reject(err);
                } finally {
                    setActiveRequest(null);
                    setLoading(false);
                }
            }, 100))
        });
        
        return promise;
    }, [baseUrl, endpoint, requestOptions, timeoutFetch, activeRequest])

    const reset = useCallback(() => {
        setData(null)
        setError(null)
        setLoading(false)
    }, [])

    return { fetch: fetchApi, loading, error, data, reset };
};

export const useApi = ({ baseUrl, requestOptions }) => {
    const api = useMemo(() => {
        const user = {
            useMe: () => useApiRequest(baseUrl, `/user/me`, {
                method: 'GET',
                ...requestOptions
            }),
            useInvoice: () => useApiRequest(baseUrl, `/user/invoice`, {
                method: 'POST',
                ...requestOptions
            }),
            useUpdateNickname: () => useApiRequest(baseUrl, `/user/nickname`, {
                method: 'POST',
                ...requestOptions
            }),
            useBots: () => useApiRequest(baseUrl, `/user/bots`, {
                method: 'GET',
                ...requestOptions
            }),
            useChats: () => useApiRequest(baseUrl, `/user/chats`, {
                method: 'GET',
                ...requestOptions
            }),
            useSetLanguage: () => useApiRequest(baseUrl, `/user/language`, {
                method: 'POST',
                ...requestOptions
            }),
            useEnableAdult: () => useApiRequest(baseUrl, `/user/enable-adult`, {
                method: 'POST',
                ...requestOptions
            }),
            useBrowsingLevel: () => useApiRequest(baseUrl, `/user/browsing-level`, {
                method: 'POST',
                ...requestOptions
            }),
        }

        const character = {
            useList:() => useApiRequest(baseUrl, `/character`, {
                method: 'GET',
                ...requestOptions
            }),
            useSearch: () => useApiRequest(baseUrl, `/character/search`, {
                method: 'GET',
                ...requestOptions
            }),
            useCreate: () => useApiRequest(baseUrl, `/character/create`, {
                method: 'POST',
                ...requestOptions
            }),
            useGet: (id) => useApiRequest(baseUrl, `/character/${id}`, {
                method: 'GET',
                ...requestOptions
            }),
            useMemories: (id) => useApiRequest(baseUrl, `/character/${id}/memories`, {
                method: 'GET',
                ...requestOptions
            }),
            useUpdateMemories: (id) => useApiRequest(baseUrl, `/character/${id}/memories`, {
                method: 'PUT',
                ...requestOptions
            }),
            useUpdateProfile: (id) => useApiRequest(baseUrl, `/character/${id}/profile`, {
                method: 'PUT',
                ...requestOptions
            }),
            useConnectTgBot: (id) => useApiRequest(baseUrl, `/character/tg/connect/${id}`, {
                method: 'POST',
                ...requestOptions
            }),
            useDisconnectTgBot: () => useApiRequest(baseUrl, `/character/tg/disconnect`, {
                method: 'POST',
                ...requestOptions
            }),
            useFavorite: (id) => useApiRequest(baseUrl, `/character/${id}/favorite`, {
                method: 'POST',
                ...requestOptions
            }),
            useLike: (id) => useApiRequest(baseUrl, `/character/${id}/like`, {
                method: 'POST',
                ...requestOptions
            }),
            useAvatarUpload: (id) => useApiRequest(baseUrl, `/character/${id}/avatar`, {
                method: 'POST',
                ...requestOptions
            }),
            useBots: (id) => useApiRequest(baseUrl, `/character/${id}/bots`, {
                method: 'GET',
                ...requestOptions
            }),
            useImport: () => useApiRequest(baseUrl, `/character/import`, {
                method: 'POST',
                ...requestOptions
            }),
            useGenerate: () => useApiRequest(baseUrl, `/character/generate`, {
                method: 'POST',
                ...requestOptions
            }),
            useSearchLora: (id) => useApiRequest(baseUrl, `/character/${id}/models/search`, {
                method: 'GET',
                ...requestOptions
            }),
            useUpdateLora: (id) => useApiRequest(baseUrl, `/character/${id}/lora`, {
                method: 'PUT',
                ...requestOptions
            }),
        };

        return {
            character,
            user,
        };
    }, [baseUrl, requestOptions])

    return api
};
